import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
  {
    path: '/loginByToken',
    name: 'LoginByToken',
    component: () => import('../views/login/loginByToken')
  },
  {
    path: '/',
    name: '/',
    component: () => import('../layout'),
    redirect: '/categories',
    children: [
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/users/index.vue')
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import('@/views/categories/index.vue')
      },
      {
        path: 'category_add',
        name: 'category_add',
        component: () => import('@/views/categories/categoryAdd.vue')
      },
      {
        path: 'goods',
        name: 'goods',
        component: () => import('@/views/goods/index.vue')
      },
      {
        path: 'goods_add',
        name: 'goods_add',
        component: () => import('@/views/goods/goodsAdd.vue')
      },
      {
        path: 'images',
        name: 'images',
        component: () => import('@/views/images/index.vue')
      },
      {
        path: 'videos',
        name: 'videos',
        component: () => import('@/views/videos/index.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('@/views/orders/index.vue')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/news/index.vue')
      },
      {
        path: 'news_category',
        name: 'news_category',
        component: () => import('@/views/news/newsCategory.vue')
      },

      {
        path: 'news_add',
        name: 'news_add',
        component: () => import('@/views/news/newsAdd.vue')
      },
      {
        path: 'params',
        name: 'params',
        component: () => import('@/views/params/index.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/views/reports/index.vue')
      },
      {
        path: 'rights',
        name: 'rights',
        component: () => import('@/views/rights/index.vue')
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import('@/views/roles/index.vue')
      },
      {
        path: 'showSets',
        name: 'showSets',
        component: () => import('@/views/sets/showSet.vue')
      },
      {
        path: 'adSets',
        name: 'adSets',
        component: () => import('@/views/sets/adSet.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
